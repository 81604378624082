import { startTransition, StrictMode } from "react";
import { hydrate } from "react-dom";

import { RemixBrowser } from "@remix-run/react";

import { ResetCacheProvider } from "./styles/CSSContext";

/**
 * @see https://github.com/facebook/react/issues/24430
 *
 * remix inserts application "too high" in the document (the whole document actually)
 * and leaves no room for third party extensions or script to modify dom prior to hydration
 *
 * wait until fix before passing to react 18 😢
 *
import { hydrateRoot } from "react-dom/client";
const load = () =>
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <CacheProvider value={clientSideCache}>
          <RemixBrowser />
        </CacheProvider>
      </StrictMode>,
    );
  });
 */
const load = () =>
  startTransition(() =>
    hydrate(
      <StrictMode>
        <ResetCacheProvider>
          <RemixBrowser />
        </ResetCacheProvider>
      </StrictMode>,
      document,
    ),
  );

if (window.requestIdleCallback) {
  window.requestIdleCallback(load);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(load, 1);
}
